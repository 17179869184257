<template>
  <div class="input">
    <el-dialog :title="`${details.params.address}客房详情`" :visible.sync="dialogDetailsShow" @close="closeDialogDetails" :close-on-click-modal="false" width="1025px" class="screen">
      <div v-loading="roomDetailLoading">
        <el-form inline :label-width="screenLabelWidth">
          <el-row>
            <el-form-item label="客房地址">
              <el-input v-model="details.params.address" disabled size="mini" style="width: 230px"></el-input>
            </el-form-item>
            <el-form-item label="房间类型">
              <el-input v-model="details.params.hsRoomType" disabled v-if="details.state" size="mini" class="screenInput"></el-input>
              <el-select v-model="details.params.hsRoomType" v-else size="mini" class="screenInput">
                <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="可住人数">
              <el-input v-model="details.params.hsResidentiality" :disabled="details.state" size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="发布状态">
              <el-input v-model="details.hsMicronetIdentificationState" disabled v-if="details.state" size="mini" class="screenInput"></el-input>
              <el-select v-model="details.params.hsMicronetIdentification" v-else size="mini" class="screenInput">
                <el-option label="已发布" :value="2"></el-option>
                <el-option label="未发布" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!--<el-row>
            <el-form-item label="客房标题">
              <el-input v-model="details.params.hsHouseTitle" :disabled="details.state" size="mini" style="width: 830px"></el-input>&nbsp;&nbsp;
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="客房详情">
              <el-input v-model="details.params.hsHousingIntroduction" :disabled="details.state" size="mini" style="width: 830px"></el-input>
            </el-form-item>
          </el-row>-->
          <el-row>
            <el-form-item label="背景音乐">
              <el-input v-model="details.totalHouse.deviceName" disabled size="mini" style="width: 230px"></el-input>
            </el-form-item>
            <el-form-item label="账号">
              <el-input v-model="details.totalHouse.account" disabled size="mini" style="width: 195px"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="details.totalHouse.password" disabled size="mini" style="width: 195px"></el-input>
            </el-form-item>
            <el-form-item><!--授权管理-->
              <el-button size="mini" @click="addDoorCard" style="width: 90px; margin-left: 30px" v-if="authe[`Hotel_Core_${pageName}_CardManagement`]">授权管理</el-button>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="客房配置">
              <div style="width: 900px">
                <el-button :type="value.type" @click="buttonClick(value)" :disabled="details.state" v-for="(value,i) in details.buttonArray" :key="i" size="mini" style="width: 90px;margin: 0 10px 5px 0">{{value.name}}</el-button>
              </div>
            </el-form-item>
          </el-row>
        </el-form>
        <el-table
          :data="details.followUp.dataFollowUp"
          highlight-current-row
          @row-dblclick="followUpDetails"
          height="216px"
          size="mini">
          <el-table-column prop="jhfFollowTime" label="跟进时间" width="150px"></el-table-column>
          <el-table-column prop="suStaffName" label="跟进人" min-width="25px"></el-table-column>
          <el-table-column prop="jhfPaymentWay" label="跟进类型" min-width="25px"></el-table-column>
          <el-table-column prop="jhfFollowBelong" label="跟进归属" min-width="25px "></el-table-column>
          <el-table-column prop="jhfFollowRemark" show-overflow-tooltip label="跟进内容" min-width="100px"></el-table-column>
        </el-table>
        <div style="text-align:center"><!--分页-->
          <el-pagination
          background layout="total, sizes, prev, pager, next, jumper"
          :page-count="details.followUp.pages"
          :page-size="details.followUp.params.limit"
          :total="details.followUp.total"
          :current-page="details.followUp.params.cursor"
          @current-change="followUpCurrentChange"
          @size-change="followUpSizeChange"
          :page-sizes="[5,10,20,40]">
          </el-pagination>
        </div>
        <div slot="footer" style="text-align: center">
          <!-- <el-button type="success" @click="cleanRoom" v-if="details.dirty && authe[`Hotel_Core_${pageName}_Clean`]">清洁完成</el-button> -->
          <el-button type="success" @click="cleanRoom(datas)" v-if="datas=='脏'">清洁完成</el-button>
          <el-button type="success" @click="cleanRoom(datas)" v-if="datas=='修' || datas=='维' ">维保完成</el-button>
          <el-button type="primary" @click="details.state = !details.state" v-if="details.state && authe[`Hotel_Core_${pageName}_ModifyRoom`]">修 改</el-button>
          <el-button type="primary" @click="modifyRoomSave"  v-if="!details.state && authe[`Hotel_Core_${pageName}_ModifyRoom`]">保 存</el-button>
          <el-button @click="dialogDetailsShow = false">取 消</el-button>
        </div>
      </div>
      <!--    门卡详情弹窗 开始-->
      <el-dialog :title="`${details.params.address}授权详情`" :visible.sync="details.doorCard.dialogDoorCard" @close="details.doorCard.dataDoorCard = []" append-to-body :close-on-click-modal="false" width="800px">
        <el-row>
          <el-button type="primary" @click="grantDoorCard" size="mini">授权</el-button>
          <el-button type="primary" @click="CancellationDoorCard" size="mini">注销</el-button>
        </el-row>
        <el-row>
          <el-table
          :data="details.doorCard.dataDoorCard"
          highlight-current-row
          @current-change="doorCardTableCurrentChange"
          height="216px"
          size="mini">
            <el-table-column prop="devNickname" label="锁名" min-width="25px"></el-table-column>
            <el-table-column prop="jdcCardId" label="卡号" min-width="25px"></el-table-column>
            <el-table-column prop="jdcPassword" label="密码" min-width="25px"></el-table-column>
            <el-table-column prop="popName" label="使用者" min-width="25px"></el-table-column>
            <el-table-column prop="jdcState" label="状态" min-width="25px "></el-table-column>
          </el-table>
          <div style="text-align:center"><!--分页-->
            <el-pagination
            background layout="total, sizes, prev, pager, next, jumper"
            :page-count="details.doorCard.pages"
            :page-size="details.doorCard.params.limit"
            :total="details.doorCard.total"
            :current-page="details.doorCard.params.cursor"
            @current-change="doorCardCurrentChange"
            @size-change="doorCardSizeChange"
            :page-sizes="[5,10,20,40]">
            </el-pagination>
          </div>
        </el-row>
      </el-dialog>
      <!--    门卡详情弹窗 结束-->
      <!--    门卡授权窗口 开始-->
      <el-dialog title="授权" :visible.sync="details.followUp.authorize.authorizeDetails" @close="authorizeOnclose" append-to-body width="600px">
        <el-form inline :label-width="screenLabelWidth" :model="password" :rules="password.rules" ref="addPassword">
          <el-row>
            <el-form-item label="选择门锁">
              <el-radio-group v-model="details.followUp.checkList" @change="radioChange" size="mini">
                <el-radio v-for="(doorLock, i) in details.followUp.doorLocks" :label="doorLock.jourDeviceId" :key="i">{{doorLock.devNickname}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="用户">
              <el-select v-model="details.followUp.doorCardPARM.jdcUserId" size="mini" class="screenInput">
                <el-option v-for="(value,i) in userName" :key="i" :label="value.suStaffName" :value="value.userCoding"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="授权方式">
              <el-radio v-model="details.followUp.doorCardPARM.authorizeType" label="0">门卡授权</el-radio>
              <el-radio v-model="details.followUp.doorCardPARM.authorizeType" label="1">密码授权</el-radio>
              <el-radio v-if="details.followUp.secondType == 58 || details.followUp.secondType == 87" v-model="details.followUp.doorCardPARM.authorizeType" label="2">离线密码授权</el-radio>
            </el-form-item>
          </el-row>
          <div v-if="details.followUp.doorCardPARM.authorizeType == 0">
            <el-row>
              <el-form-item label="授权">
                <el-input v-model="details.followUp.doorCardPARM.jdcCardId" type="password" size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="期限">
                <el-date-picker v-model="details.followUp.doorCardPARM.jdcDeadlineTime" type="datetime" placeholder="选择日期时间" default-time="12:00:00" format="yyyy-MM-dd  HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" clearable size="mini"  style="width:125px"></el-date-picker>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="卡号">
                <el-input v-model="details.followUp.doorCardPARM.jdcCardNum" size="mini" class="screenInput"></el-input>
              </el-form-item>
            </el-row>
          </div>
          <div v-else-if="details.followUp.doorCardPARM.authorizeType == 1">
            <el-form-item label="限次/限时">
              <el-radio v-model="details.followUp.radio1" label="开锁次数">开锁次数</el-radio>
              <el-radio v-model="details.followUp.radio1" label="开锁期限">开锁期限</el-radio>
            </el-form-item>
            <div v-if = "details.followUp.radio1=='开锁次数'">
              <el-form-item label="次数">
                <el-input v-model="details.followUp.doorCardPARM.jdcMaxUnlockingTimes" size="mini" class="screenInput"></el-input>
              </el-form-item>
            </div>
            <div v-else-if ="details.followUp.radio1=='开锁期限'">
              <el-form-item label="期限">
                <el-date-picker v-model="details.followUp.doorCardPARM.jdcDeadlineTime" type="datetime" placeholder="选择日期时间"  default-time="12:00:00" format="yyyy-MM-dd  HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" clearable size="mini"  style="width:125px"></el-date-picker>
              </el-form-item>
            </div>
          </div>
          <div v-else-if="details.followUp.doorCardPARM.authorizeType == 2">
            <div v-if="details.followUp.secondType == 58">
              <el-form-item>
                <el-select v-model="password.offlineSecondType" @change="clearPasswordDialog" size="mini" class="screenInput">
                  <el-option label="次数密码" :value="0"></el-option>
                  <el-option label="时数密码" :value="1"></el-option>
                  <el-option label="天数密码" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="indefinitely" prop="offlineUnlockTimes" label-width="55px">
                <el-input v-model="password.offlineUnlockTimes" size="mini" @change="indefinitelyChange" class="screenInput"></el-input>
              </el-form-item>
            <el-row>
              <span>
                <el-form-item label="生效时间" prop="offlineDate" label-width="85px">
                  <el-date-picker
                    v-model="password.offlineDate"
                    type="date"
                    :disabled="effectTimeShow"
                    @change="effectDateChange"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    style="width:125px"
                    :picker-options="dateLimit">
                  </el-date-picker>
                </el-form-item>
                <el-form-item prop="offlineHours">
                  <el-select v-model="password.offlineHours" :disabled="effectTimeShow" placeholder="选择小时" @change="changeHours" size="mini" style="width: 100px;" class="none-icon">
                    <el-option
                      v-for="item in optionsHours"
                      :key="item.value"
                      :label="item.label"
                      :disabled="item.disabled"
                      :value="item.value">
                    </el-option>
                </el-select>
                </el-form-item>
                <el-form-item>:</el-form-item>
                <el-form-item prop="offlineMinutes">
                  <el-select v-model="password.offlineMinutes"  @change="changeFailureTime" placeholder="选择分钟" :disabled="showMinutes" size="mini" style="width: 100px;" class="none-icon">
                    <el-option
                      v-for="item in optionsMinutes"
                      :key="item.value"
                      :label="item.label"
                      :disabled="item.disabled"
                      :value="item.value">
                    </el-option>
                </el-select>
                </el-form-item>
              </span>
            </el-row>
            <el-row>
              <span>
                <el-form-item label="失效时间" prop="failureDate" label-width="85px">
                  <el-date-picker
                    v-model="password.failureDate"
                    readonly
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    style="width:125px"
                    :picker-options="dateLimit">
                  </el-date-picker>
                </el-form-item>
                <el-form-item prop="failureHours">
                  <el-select v-model="password.failureHours" disabled placeholder="选择小时" size="mini" style="width: 100px;" class="none-icon">
                    <el-option
                      v-for="item in failureoptionsHours"
                      :key="item.value"
                      :label="item.label"
                      :disabled="item.disabled"
                      :value="item.value">
                    </el-option>
                </el-select>
                </el-form-item>
                <el-form-item>:</el-form-item>
                <el-form-item prop="failureMinutes">
                  <el-select v-model="password.failureMinutes" disabled placeholder="选择分钟" size="mini" style="width: 100px;" class="none-icon">
                    <el-option
                      v-for="item in optionsMinutes"
                      :key="item.value"
                      :label="item.label"
                      :disabled="item.disabled"
                      :value="item.value">
                    </el-option>
                </el-select>
                </el-form-item>
              </span>
            </el-row>
            </div>
          </div>
        </el-form>
        <div slot="footer">
          <el-button @click="details.followUp.authorize.authorizeDetails = false">取 消</el-button>
          <el-button type="primary" @click="authorization">确 定</el-button>
        </div>
      </el-dialog>
      <!--    门卡授权窗口 结束-->
      <!--    跟进详细信息弹窗 开始-->
      <el-dialog title="跟进详细信息" :visible.sync="details.followUp.dialogFollowUpDetails" append-to-body :close-on-click-modal="false" width="460px">
        <el-form inline :label-width="screenLabelWidth">
          <el-row>
            <el-form-item label="跟进时间">
              <el-input v-model="details.followUp.followUpDetails.jhfFollowTime" disabled size="mini" style="width:335px"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="跟进人">
              <el-input v-model="details.followUp.followUpDetails.suStaffName" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="跟进类型">
              <el-input v-model="details.followUp.followUpDetails.jhfPaymentWay" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="跟进归属">
              <el-input v-model="details.followUp.followUpDetails.jhfFollowBelong" disabled size="mini" style="width: 335px"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="跟进内容">
              <el-input v-model="details.followUp.followUpDetails.jhfFollowRemark" disabled type="textarea" rows="4" size="mini" style="width:335px"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </el-dialog>
      <!--    跟进详细信息弹窗 结束-->
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment'
export default {
  props: {
    datas:{
      type:String
    },
    isDialogShow: {
      type: Boolean,
      default: false,
    },
    isRowData: {
      type: Object,
      default: null,
    },
    isPageName: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      dialogDetailsShow: this.isDialogShow,
      row: this.isRowData,
      pageName: this.isPageName,
      roomDetailLoading: true,
      screenLabelWidth: '75px',
      details: {
        state: true,
        dirty: false,
        hsMicronetIdentificationState: '',//发布状态,
        params: {
          address: '',//客房地址
          hsRoomType: '',//房间类型
          hsResidentiality: '',//可住人数
          hsMicronetIdentification: '',//发布状态
          hsHouseTitle: '',//客房标题
          hsHousingIntroduction: '',//客房详情
          hsFurnitureConfig: '',//客房配置
        },
        buttonArray: [//按钮数组
          {
            name: '热水淋浴',
            type: ''
          },{
            name: '无线网络',
            type: ''
          },{
            name: '空调',
            type: ''
          },{
            name: '电视',
            type: ''
          },{
            name: '门禁系统',
            type: ''
          },{
            name: '停车位',
            type: ''
          },{
            name: '热水壶',
            type: ''
          },{
            name: '有线网络',
            type: ''
          },{
            name: '电脑',
            type: ''
          },{
            name: '拖鞋',
            type: ''
          },{
            name: '纸巾',
            type: ''
          },{
            name: '牙具',
            type: ''
          },{
            name: '毛巾',
            type: ''
          },{
            name: '溶液',
            type: ''
          },{
            name: '洗发水',
            type: ''
          },{
            name: '香皂',
            type: ''
          },{
            name: '浴巾',
            type: ''
          },{
            name: '剃须刀',
            type: ''
          },{
            name: '吹风筒',
            type: ''
          },{
            name: '适宜儿童',
            type: ''
          },{
            name: '适宜老人',
            type: ''
          },{
            name: '适宜残疾人',
            type: ''
          },{
            name: '电梯',
            type: ''
          },{
            name: '洗衣机',
            type: ''
          },{
            name: '冰箱',
            type: ''
          },{
            name: '浴缸',
            type: ''
          },{
            name: '暖气',
            type: ''
          },
        ],
        doorCard: {
          dialogDoorCard: false,
          params: {
            limit: 5,//每页的条数
            cursor: 1,//初始页
          },
          total: 0,//总条数
          pages: 0,//每页数据
          dataDoorCard: [],
          rowData: null,
        },
        followUp: {
          dialogFollowUpDetails: false,
          params: {
            jhfHouse4storeId: '',
            limit: 5,//每页的条数
            cursor: 1,//初始页
          },
          followUpDetails: {
            jhfFollowTime: '',//跟进时间
            suStaffName: '',//跟进人
            jhfPaymentWay: '',//跟进类型
            jhfFollowBelong: '',//跟进归属
            jhfFollowRemark: '',//跟进内容
          },
          checkList:'',
          secondType:'',
          doorLocks:'',
          authorize: {
            authorizeDetails: false,
          },
          doorCardPARM:{
            authorizeType: null,
            deviceIds: '',
            houseAddress: '',
            jdcCardId: '',
            jdcCardNum: '',
            jdcDeadlineTime: '',
            jdcHsId: null,
            jdcMaxUnlockingTimes: null,
            jdcUserId: null,
            popId: null
          },
          radio1:'1',
          endTime: '',
          total: 0,//总条数
          pages: 0,//每页数据
          rowData: {},
          dataFollowUp: [],
        },
        totalHouse: {
          deviceName: '',
          account: '',
          password: ''
        }
      },
      password: {//902锁添加离线密码
        rules: {//验证规则
          offlineUnlockTimes: [
            {required: true,message: '次数不能为空',trigger: 'blur',},
            {min: 1,max: 10,message: '次数不能为空长度为 6 到 10 个字符',trigger: 'blur',}
          ],
          offlineDate: [
            {required: true,message: '生效时间不能为空',trigger: 'change',},
          ],
          offlineHours: [
            {required: true,message: '小时不能为空',trigger: 'change',},
          ],
          offlineMinutes: [
            {required: true,message: '分钟不能为空',trigger: 'change',},
          ],
          failureDate: [
            {required: true,message: '失效时间不能为空',trigger: 'change',},
          ],
          failureHours: [
            {required: true,message: '小时不能为空',trigger: 'change',},
          ],
          failureMinutes: [
            {required: true,message: '分钟不能为空',trigger: 'change',},
          ],
        },
        offlineSecondType:0,
        offlineUnlockTimes:'',
        offlineDate: '',
        offlineHours: '',
        offlineMinutes: '',
        failureDate: '',
        failureHours: '',
        failureMinutes: '',
      },
      indefinitely: '',
      effectTimeShow: '',
      showMinutes: '',
      optionsMinutes : [
        {
          value: '00',
          label: '00',
          disabled: false
        },
        {
          value: '20',
          label: '20',
          disabled: false
        },
        {
          value: '40',
          label: '40',
          disabled: false
        },
      ],
      optionsHours: [
        {
          value: '00',
          label: '00',
          disabled: false
        },{
          value: '01',
          label: '01',
          disabled: false
        },
        {
          value: '02',
          label: '02',
          disabled: false
        },
        {
          value: '03',
          label: '03',
          disabled: false
        },
        {
          value: '04',
          label: '04',
          disabled: false
        },
        {
          value: '05',
          label: '05',
          disabled: false
        },
        {
          value: '06',
          label: '06',
          disabled: false
        },
        {
          value: '07',
          label: '07',
          disabled: false
        },
        {
          value: '08',
          label: '08',
          disabled: false
        },
        {
          value: '09',
          label: '09',
          disabled: false
        },
        {
          value: '10',
          label: '10',
          disabled: false
        },
        {
          value: '11',
          label: '11',
          disabled: false
        },
        {
          value: '12',
          label: '12',
          disabled: false
        },
        {
          value: '13',
          label: '13',
          disabled: false
        },
        {
          value: '14',
          label: '14',
          disabled: false
        },
        {
          value: '15',
          label: '15',
          disabled: false
        },
        {
          value: '16',
          label: '16',
          disabled: false
        },
        {
          value: '17',
          label: '17',
          disabled: false
        },
        {
          value: '18',
          label: '18',
          disabled: false
        },
        {
          value: '19',
          label: '19',
          disabled: false
        },
        {
          value: '20',
          label: '20',
          disabled: false
        },
        {
          value: '21',
          label: '21',
          disabled: false
        },
        {
          value: '22',
          label: '22',
          disabled: false
        },
        {
          value: '23',
          label: '23',
          disabled: false
        },
      ],
      failureoptionsHours: [
        {
          value: '00',
          label: '00',
          disabled: false
        },{
          value: '01',
          label: '01',
          disabled: false
        },
        {
          value: '02',
          label: '02',
          disabled: false
        },
        {
          value: '03',
          label: '03',
          disabled: false
        },
        {
          value: '04',
          label: '04',
          disabled: false
        },
        {
          value: '05',
          label: '05',
          disabled: false
        },
        {
          value: '06',
          label: '06',
          disabled: false
        },
        {
          value: '07',
          label: '07',
          disabled: false
        },
        {
          value: '08',
          label: '08',
          disabled: false
        },
        {
          value: '09',
          label: '09',
          disabled: false
        },
        {
          value: '10',
          label: '10',
          disabled: false
        },
        {
          value: '11',
          label: '11',
          disabled: false
        },
        {
          value: '12',
          label: '12',
          disabled: false
        },
        {
          value: '13',
          label: '13',
          disabled: false
        },
        {
          value: '14',
          label: '14',
          disabled: false
        },
        {
          value: '15',
          label: '15',
          disabled: false
        },
        {
          value: '16',
          label: '16',
          disabled: false
        },
        {
          value: '17',
          label: '17',
          disabled: false
        },
        {
          value: '18',
          label: '18',
          disabled: false
        },
        {
          value: '19',
          label: '19',
          disabled: false
        },
        {
          value: '20',
          label: '20',
          disabled: false
        },
        {
          value: '21',
          label: '21',
          disabled: false
        },
        {
          value: '22',
          label: '22',
          disabled: false
        },
        {
          value: '23',
          label: '23',
          disabled: false
        },
      ],
      dateLimit:{//日期限制
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    }
  },
  computed: {
    ...mapState(['setupList','userName','authe'])
  },
  created(){
    this.dbRoom(this.row)
  },
  watch: {
    dialogDetailsShow(n, o) {
      this.$emit('close');
    },
  },
  methods: {
    async dbRoom(row){
      try {
        await this.modifyRoom(row)
        await this.followUpRoom(row)
        await this.getTotalHouse(row)
      } catch (err) {
        console.error(err)
      }
    },
    modifyRoom(row){//修改客房
      this.roomDetailLoading = true
      this.$axios({
        method: 'get',
        url: `/hotel/house4store/${row.hsId}`,
      }).then(res => {
        if (res.data.result) {
          let a = res.data.result.hsAddCommunity ? `${res.data.result.hsAddCommunity}\u3000` : ''
          let b = res.data.result.hsRoomType ? `${res.data.result.hsRoomType}\u3000` : ''
          let c = res.data.result.hsAddDoorplateno ? `${res.data.result.hsAddDoorplateno}` : ''
          res.data.result.address = a + b + c
          for (let i in res.data.result) {
            for (let k in this.details.params) {
              if (i == k) {
                this.details.params[k] = res.data.result[i]
              }
            }
          }
          this.details.hsMicronetIdentificationState = this.details.params.hsMicronetIdentification == 1 ? '未发布' : '已发布'
          if (res.data.result.hsFurnitureConfig) {
            let arr = []
            arr = res.data.result.hsFurnitureConfig.split(" ")
            for (let k of arr) {
              for (let j of this.details.buttonArray) {
                if (k == j.name) {
                  j.type = 'primary'
                }
              }
            }
          }
          this.details.dirty = res.data.result.hsDirtyHouse
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    followUpRoom(row){//查询客房跟进
      this.details.followUp.params.jhfHouse4storeId = row.hsId,
      this.$axios({
        method: 'get',
        url: `/hotel/eventFollowupRecords`,
        params: this.details.followUp.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.details.followUp.dataFollowUp = res.data.result.records
          this.details.followUp.total = res.data.result.total
        } else {
          this.details.followUp.dataFollowUp = []
          this.details.followUp.total = 0
        }
        this.roomDetailLoading = false
        this.dialogDetailsShow = true
      }).catch(err => {
        this.roomDetailLoading = false
        this.isError(err,this)
      })
    },
    getTotalHouse(row){
      this.$axios({
        method: 'get',
        url: `/hotel/totalHouse/${row.hsId}`,
      }).then(res => {
        this.details.totalHouse = res.data.result
      }).catch(err => {
        this.isError(err,this)
      })
    },
    buttonClick(row){//点击时切换按钮颜色
      if (row.type == '') {
        row.type = 'primary'
      } else {
        row.type = ''
      }
    },
    followUpCurrentChange(cursor){//点击更改点前页数
      this.details.followUp.params.cursor = cursor;
      this.followUpRoom(this.row)
    },
    followUpSizeChange(limit){//点击更改显示条数
      this.details.followUp.params.limit = limit;
      this.followUpRoom(this.row)
    },
    followUpDetails(row){
      this.$axios({
        method: 'get',
        url: `/hotel/eventFollowupRecords`,
        params: {
          jhfId: row.jhfId
        }
      }).then(res => {
        if (res.data.result.records.length > 0) {
          for (let i in res.data.result.records[0]) {
            for (let k in this.details.followUp.followUpDetails) {
              if (i == k) {
                this.details.followUp.followUpDetails[k] = res.data.result.records[0][i]
              }
            }
          }
        }
        this.details.followUp.dialogFollowUpDetails = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    modifyRoomSave(){//修改客房保存
      this.roomDetailLoading = true
      let arr = []
      for (let i of this.details.buttonArray) {
        if (i.type == 'primary') {
          arr.push(i.name)
        }
      }
      this.details.params.hsFurnitureConfig = arr.join(' ')
      this.$axios({
        method: 'put',
        url: `/hotel/house4store/${this.row.hsId}`,
        data: this.details.params
      }).then(res => {
        this.$message.success('修改成功')
        // this.dialogDetailsShow = false
        this.details.state = !this.details.state
        this.roomDetailLoading = false
      }).catch(err => {
        this.roomDetailLoading = false
        this.isError(err,this)
      })
    },
    closeDialogDetails(){
      this.details.state = true
      this.details.hsMicronetIdentificationState = ''
      this.details.params = {
        address: '',//客房地址
        hsRoomType: '',//房间类型
        hsResidentiality: '',//可住人数
        hsMicronetIdentification: '',//发布状态
        hsHouseTitle: '',//客房标题
        hsHousingIntroduction: '',//客房详情
        hsFurnitureConfig: '',//客房配置
      }
      this.details.followUp.dataFollowUp = []
      for (let i of this.details.buttonArray) {
        i.type = ''
      }
      this.details.dirty = 0
      this.details.totalHouse = {
        deviceName: '',
        account: '',
        password: ''
      }
    },
    cleanRoom(value){//清洁完成
      let msg=''
      if(value=='脏'){
        msg='清洁完成'
      }else if(value=='修' || value=='维'){
        msg='维保完成'
      }
      this.$axios({
        method: 'put',
        url: `/hotel/shortRentContract/completeMaintenance`,
        params: {
          hsIds: this.row.hsId
        }
      }).then(res => {
        this.$message.success(msg)
        this.dialogDetailsShow = false
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //门卡管理
      addDoorCard(){//门卡管理
        this.details.doorCard.dialogDoorCard = true;
        this.$axios({
          method:'get',
          url:`/common2/doorCard/${this.row.hsId}`,
          params:this.details.doorCard.params
        }).then(res=>{
          if (res.data.result.records.length > 0) {
            this.details.doorCard.dataDoorCard = res.data.result.records;
            this.details.doorCard.total = res.data.result.total
            this.details.doorCard.rowData = null
          } else {
            this.details.doorCard.dataDoorCard = []
            this.details.doorCard.total = 0
            this.details.doorCard.rowData = null
          }
        }).catch(err=>{
          this.isError(err,this)
        })
      },
    //门卡授权
      grantDoorCard(){
        this.$axios({
          method: 'get',
          url: `/common2/doorCard/${this.row.hsId}/getLock`
        }).then(res=>{
          if (res.data.result.records.length == 0) {
            this.$message.warning("房间不存在授权设备！");
          } else {
            this.details.followUp.authorize.authorizeDetails = true;
            this.details.followUp.doorLocks = res.data.result.records;
          }
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      radioChange(val) {
        for (let i of this.details.followUp.doorLocks) {
          if (i.jourDeviceId == this.details.followUp.checkList) {
            this.details.followUp.secondType = i.devSecondType
          }
        }
      },
      clearPasswordDialog(){
        // this.password.rules.offlineUnlockTimes = []
        // this.password.rules.offlineUnlockTimes = [{required: true,message: '次数不能为空',trigger: 'blur'},{min: 1,max: 50,message: '次数不能为空长度为 6 到 10 个字符',trigger: 'blur'}]
        //选择小时不能小于当前时间
        for(let i of this.optionsHours){
          i.disabled = i.label < moment().format(`HH`) ? true : false
        }
        if(this.password.offlineSecondType == 0){
          this.indefinitely = '次数'
        }else if(this.password.offlineSecondType == 1){
          this.indefinitely = '时数'
        }else if(this.password.offlineSecondType == 2){
          this.indefinitely = '天数'
        }
        this.password.offlineUnlockTimes = ''
        this.password.offlineDate = ''
        this.password.offlineHours = ''
        this.password.offlineMinutes = ''
        this.password.failureDate = ''
        this.password.failureHours = ''
        this.password.failureMinutes = ''
        this.password.title = ''
        this.effectTimeShow = true
        this.showMinutes = true
        // this.resetForm(['addPassword'],this)
      },
      effectDateChange(){
        let time = moment().format(`YYYY-MM-DD`)
        if(this.password.offlineDate > time){
          for(let i of this.optionsHours){
            i.disabled = false
          }
          for(let i of this.optionsMinutes){
            i.disabled = false
          }
        }else{
          for(let i of this.optionsHours){
            i.disabled = i.label < moment().format(`HH`) ? true : false
          }
        }
        this.changeFailureTime()
      },
      changeHours(){
        for(let i in this.optionsMinutes){
          let min = Number(this.optionsMinutes[i].label) + 20
          let time = `${this.password.offlineHours}:${min}`
          this.optionsMinutes[i].disabled = time < moment().format(`HH:mm`) ? true : false
        }
        let time2 = moment().format(`YYYY-MM-DD`)
        if(this.password.offlineDate > time2){
          for(let i of this.optionsMinutes){
            i.disabled = false
          }
        }
        this.changeFailureTime()
      },
      changeFailureTime () {
        if(this.password.offlineSecondType == '0'){
          this.password.failureDate = moment().add(1,'days').format(`YYYY-MM-DD`)
          this.password.failureHours = this.password.offlineHours
          this.password.failureMinutes = this.password.offlineMinutes
        }else if(this.password.offlineSecondType == '1'){
          let number = parseInt((Number(this.password.offlineHours) + Number(this.password.offlineUnlockTimes))/24)
          if (number > 0) {
            let time = `${this.password.offlineDate} ${this.password.offlineHours}:${this.password.offlineMinutes}:00`
            let newDate = moment(time).add(this.password.offlineUnlockTimes,'hours')
            this.password.failureDate = moment(newDate).format(`YYYY-MM-DD`)
            this.password.failureHours = moment(newDate).format(`HH`)
            this.password.failureMinutes = moment(newDate).format(`mm`)
          } else {
            this.password.failureDate = this.password.offlineDate
            this.password.failureHours = Number(this.password.offlineHours) + Number(this.password.offlineUnlockTimes)
            this.password.failureMinutes = this.password.offlineMinutes
          }
        }else if(this.password.offlineSecondType == '2'){
          let newDate =  moment(this.password.offlineDate).add(this.password.offlineUnlockTimes,'days')
          this.password.failureDate = moment(newDate).format(`YYYY-MM-DD`)
          this.password.failureHours = this.password.offlineHours
          this.password.failureMinutes = this.password.offlineMinutes
        }
        console.log(this.password.failureDate)
      },
      indefinitelyChange(){
        this.resetForm(['addPassword'],this)
        this.effectTimeShow = false
        this.showMinutes = false
        let offlineUnlockTimes = this.password.offlineUnlockTimes
        if(this.password.offlineSecondType == '0'){
          if(offlineUnlockTimes>9 || offlineUnlockTimes<1 || offlineUnlockTimes%1!=0){
            this.$message.warning('请输入1-9的次数')
            this.password.offlineUnlockTimes = ''
          }
        }else if(this.password.offlineSecondType == '1'){
          if(offlineUnlockTimes>168 || offlineUnlockTimes<1 || offlineUnlockTimes%1!=0){
            this.$message.warning('请输入1-168的小时')
            this.password.offlineUnlockTimes = ''
          }
        }else if(this.password.offlineSecondType == '2'){
          if(offlineUnlockTimes>750 || offlineUnlockTimes<1 || offlineUnlockTimes%1!=0){
            this.$message.warning('请输入1-750的天数')
            this.password.offlineUnlockTimes = ''
            this.password.failureDate = ''
            this.password.failureHours = ''
            this.password.failureMinutes = ''
          }
        }
        this.password.offlineDate = moment().format(`YYYY-MM-DD`)
        this.password.offlineHours = moment().format(`HH`)
        let minutesSec = moment().format(`mm:ss`)
        this.changeHours()
        if(minutesSec >= '00:00' && minutesSec <= '19:59'){
          this.password.offlineMinutes = '00'
        }else if(minutesSec >= '20:00' && minutesSec <= '39:59'){
          this.password.offlineMinutes = '20'
        }else if(minutesSec >= '40:00' && minutesSec <= '59:59'){
          this.password.offlineMinutes = '40'
        }
        this.changeFailureTime()
      },
    //门卡授权确定按钮
    authorization(){
      if(this.details.followUp.checkList == ''){
        this.$message.warning("请选择门锁！");
        return
      }
      if (!this.details.followUp.doorCardPARM.jdcUserId) {
        this.$message.warning("请选择用户！");
        return
      }
      this.details.followUp.doorCardPARM.deviceIds = [this.details.followUp.checkList];
      this.details.followUp.doorCardPARM.houseAddress = this.details.params.address;
      this.details.followUp.doorCardPARM.jdcHsId = this.row.hsId;
      console.log(this.details.followUp.doorCardPARM);
      if ( this.details.followUp.doorCardPARM.authorizeType == 2 &&  this.details.followUp.secondType == 58) {
        if (this.submitForm(['addPassword'],this)) {
          this.details.followUp.doorCardPARM.encryptMode = this.password.offlineSecondType
          this.details.followUp.doorCardPARM.jdcMaxUnlockingTimes = this.password.offlineUnlockTimes
          this.details.followUp.doorCardPARM.startTime = `${this.password.failureDate} ${this.password.failureHours}:${this.password.failureMinutes}:00`
        } else {
          return
        }
      }
      this.$axios({
        method: 'post',
        url: '/common2/doorCard',
        data: this.details.followUp.doorCardPARM
      }).then(res=>{
        this.details.followUp.authorize.authorizeDetails = false;
        this.addDoorCard();
        this.$message.success("授权成功！");
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    //门卡注销
      CancellationDoorCard(){
        if(this.details.doorCard.rowData == null){
          this.$message.warning('请选择一行数据');
        }else {
          this.$axios({
            method: 'put',
            url: `/common2/doorCard/${this.details.doorCard.rowData.jdcId}`
          }).then(res=>{
            this.addDoorCard();
            this.$message.success('注销成功！');
          }).catch(err=>{
            this.isError(err,this)
          })
        }
      },
    authorizeOnclose(){//授权关闭
      this.details.followUp.checkList = [];
      this.details.followUp.doorCardPARM.authorizeType = null;
      this.details.followUp.doorCardPARM.deviceIds = '';
      this.details.followUp.doorCardPARM.houseAddress = '';
      this.details.followUp.doorCardPARM.jdcCardId = '';
      this.details.followUp.doorCardPARM.jdcCardNum = '';
      this.details.followUp.doorCardPARM.jdcDeadlineTime = '';
      this.details.followUp.doorCardPARM.jdcMaxUnlockingTimes = null;
      this.details.followUp.doorCardPARM.jdcUserId = null;
      this.details.followUp.doorCardPARM.popId = null;
      this.password =  {//902锁添加离线密码
        offlineSecondType:0,
        offlineUnlockTimes:'',
        offlineDate: '',
        offlineHours: '',
        offlineMinutes: '',
        failureDate: '',
        failureHours: '',
        failureMinutes: '',
      }
      this.indefinitely = ''
      this.effectTimeShow = ''
      this.showMinutes = ''
      this.optionsHours = []
      this.optionsMinutes = []
      this.failureoptionsHours = []
    },
    doorCardTableCurrentChange(row){//选中行
      this.details.doorCard.rowData = row
    },
    doorCardCurrentChange(cursor){//点击更改点前页数
      this.details.doorCard.params.cursor = cursor;
      this.addDoorCard()
    },
    doorCardSizeChange(limit){//点击更改显示条数
      this.details.doorCard.params.limit = limit;
      this.addDoorCard()
    },
  }
}
</script>
<style lang="scss" scoped>
  .screenInput{
    width: 145px;
  }
</style>
